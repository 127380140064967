import { useRef, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Image from 'next/image';
import { useIntl } from 'react-intl';
import classnames from 'classnames';

import { NumericFormat } from 'react-number-format';
import { useRouter } from 'next/router';

import {
  AppBar,
  Button,
  List,
  ListItem,
  Select,
  Tab,
  Tabs,
  TextField,
  PromoButton,
} from 'bf-ui';

import styles from './main.header.scss';

import LogoHorizontalImg from '../../../../assets/images/logos/114x38_logo.svg';
import websiteConfig from '../../../../configs/websiteConfig';
import LogoVerticalImg from '../../../../assets/images/logos/42x42_logo.svg';
import SearchMobileIcon from '../../../../assets/images/icons/search.svg';

import SearchIcon from '../../../../assets/images/icons/search-icon-dark.svg';
import CloseMobileIcon from '../../../../assets/images/icons/close.svg';
import CloseIcon from '../../../../assets/images/icons/close-dark.svg';
import { searchGames_req } from '../../../../api/game.api';
import StorageEnum from '../../../../enums/storage.enum';
import { addToSearchHistory_req } from '../../../../api/user.api';
import { onLanguageChange } from '../../../../helpers/language.helper';
import { setDrawerOpen } from '../../../../redux/global.slice';
import LinkWrapper from '../../../wrappers/link/link.wrapper';

const SEARCH_TAB_IDS = {
  GAMES: 0,
  PROVIDERS: 1,
};

Object.freeze(SEARCH_TAB_IDS);

export default function MainHeader() {
  const intl = useIntl();
  const router = useRouter();

  const { locale } = router;

  const searchTimeoutRef = useRef();
  const searchResultRef = useRef();

  const dispatch = useDispatch();

  const { providers, isMobile } = useSelector((state) => state.global);
  const { id, balance } = useSelector((state) => state.user);

  const [showSearch, setShowSearch] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [searchFocused, setSearchFocused] = useState(false);
  const [searchTab, setSearchTab] = useState(SEARCH_TAB_IDS.GAMES);

  const [lastGameSearches, setLastGameSearches] = useState([]);
  const [lastProviderSearches, setLastProviderSearches] = useState([]);

  const [searchResult, setSearchResult] = useState([]);

  const onSearchTabChange = useCallback((tab) => {
    setSearchTab(tab);
    setSearchVal('');
    setSearchResult([]);
  }, []);

  const search = useCallback(async (value) => {
    try {
      setSearchResult(await searchGames_req(value));
    } catch (e) {
      console.error('MainLayout -> search Error:', e);
    }
  }, []);

  const onSearchFocus = useCallback(() => {
    setSearchFocused(true);
  }, []);

  const onSearch = (e) => {
    const { value } = e.target;
    setSearchVal(value);

    clearTimeout(searchTimeoutRef.current);
    if (value?.length > 2) {
      if (searchTab === SEARCH_TAB_IDS.GAMES) {
        searchTimeoutRef.current = setTimeout(() => {
          search(value);
        }, 800);
      } else if (searchTab === SEARCH_TAB_IDS.PROVIDERS && providers.length) {
        setSearchResult(
          providers.filter((data) =>
            data.Name?.toLowerCase().includes(value.toLowerCase())
          )
        );
      }
    } else {
      setSearchResult([]);
    }
  };

  const handleClickOutside = useCallback((event) => {
    if (
      searchResultRef.current &&
      !searchResultRef.current.contains(event.target)
    ) {
      setSearchTab(SEARCH_TAB_IDS.GAMES);
      setSearchFocused(false);
      setSearchVal('');
      setSearchResult([]);
    }
  }, []);

  const getSearchHistoryFromLS = useCallback(() => {
    let searchHistory = window.localStorage.getItem(StorageEnum.SEARCH_HISTORY);

    if (searchHistory) {
      try {
        searchHistory = JSON.parse(searchHistory);
      } catch (e) {
        console.error(
          'MainLayout -> getSearchHistoryFromLS -> JSON.parse Error:',
          e
        );
      }
    } else {
      searchHistory = {};
    }

    return searchHistory;
  }, []);

  const getProviderSearchHistoryFromLS = useCallback(() => {
    let searchHistory = window.localStorage.getItem(
      StorageEnum.PROVIDER_SEARCH_HISTORY
    );

    if (searchHistory) {
      try {
        searchHistory = JSON.parse(searchHistory);
      } catch (e) {
        console.error(
          'MainLayout -> getProviderSearchHistoryFromLS -> JSON.parse Error:',
          e
        );
      }
    } else {
      searchHistory = {};
    }

    return searchHistory;
  }, []);

  const onSearchGameSelect = async (data) => {
    try {
      if (!data || !data.Key || !data.Name) {
        return;
      }

      const searchHistory = getSearchHistoryFromLS();

      if (id) {
        await addToSearchHistory_req({}, data.ID);
      }

      if (searchHistory && !searchHistory[data.Key]) {
        searchHistory[data.Key] = {
          Key: data.Key,
          Name: data.Name,
          Image: data.Image,
        };
        window.localStorage.setItem(
          StorageEnum.SEARCH_HISTORY,
          JSON.stringify(searchHistory)
        );
        setLastGameSearches(Object.values(searchHistory));
      }

      setSearchVal('');
      setSearchTab(SEARCH_TAB_IDS.GAMES);
      setSearchResult([]);
      setSearchFocused(false);

      router.push(`/games/${data.Key}`, `/games/${data.Key}`);
    } catch (e) {
      console.error('MainLayout -> onSearchGameSelect Error:', e);
    }
  };

  const onSearchProviderSelect = useCallback(async (data) => {
    try {
      if (!data || !data.ID || !data.Name) {
        return;
      }

      const searchHistory = getProviderSearchHistoryFromLS();

      if (searchHistory && !searchHistory[data.ID]) {
        searchHistory[data.ID] = {
          ID: data.ID,
          Name: data.Name,
        };

        window.localStorage.setItem(
          StorageEnum.PROVIDER_SEARCH_HISTORY,
          JSON.stringify(searchHistory)
        );
        setLastProviderSearches(Object.values(searchHistory));
      }

      setSearchVal('');
      setSearchTab(SEARCH_TAB_IDS.GAMES);
      setSearchResult([]);
      setSearchFocused(false);

      router.push(
        `/providers/?provider=${data.Name}`,
        `/providers/?provider=${data.Name}`
      );
    } catch (e) {
      console.error('MainLayout -> onSearchProviderSelect Error:', e);
    }
  }, []);

  const getSearchHistory = useCallback(() => {
    const searchHistory = getSearchHistoryFromLS();
    const values = Object.values(searchHistory);

    const providerSearchHistory = getProviderSearchHistoryFromLS();
    const providerValues = Object.values(providerSearchHistory);

    if (values.length) {
      setLastGameSearches(values);
    }

    if (providerValues.length) {
      setLastProviderSearches(providerValues);
    }
  }, []);

  const getSearchListData = () => {
    if (searchResult?.length) {
      return searchResult;
    }

    if (searchTab === SEARCH_TAB_IDS.GAMES && lastGameSearches.length) {
      return [...lastGameSearches].reverse();
    }

    if (searchTab === SEARCH_TAB_IDS.PROVIDERS && lastProviderSearches.length) {
      return [...lastProviderSearches].reverse();
    }

    return [];
  };

  const onLastSearchItemRemove = useCallback(
    (itemId) => {
      try {
        if (searchTab === SEARCH_TAB_IDS.GAMES) {
          const result = getSearchHistoryFromLS();

          if (result) {
            delete result[itemId];

            window.localStorage.setItem(
              StorageEnum.SEARCH_HISTORY,
              JSON.stringify(result)
            );

            setLastGameSearches(Object.values(result));
          }
        } else if (searchTab === SEARCH_TAB_IDS.PROVIDERS) {
          const result = getProviderSearchHistoryFromLS();

          if (result) {
            delete result[itemId];

            window.localStorage.setItem(
              StorageEnum.PROVIDER_SEARCH_HISTORY,
              JSON.stringify(result)
            );

            setLastProviderSearches(Object.values(result));
          }
        }
      } catch (e) {
        console.error('MainLayout -> onLastSearchItemRemove Error: ', e);
      }
    },
    [searchTab]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchResultRef]);

  useEffect(() => {
    setShowSearch(false);
    setSearchFocused(false);
    setSearchResult([]);
  }, [router.pathname]);

  useEffect(() => {
    getSearchHistory();
  }, []);

  const Search = (
    <div className="search_wrapper action_wrapper" ref={searchResultRef}>
      <div
        className={classnames(
          'search_text_field_wrapper',
          searchFocused ? 'search_focused' : ''
        )}
      >
        <TextField
          autoComplete="false"
          className="search"
          icon={SearchIcon.src}
          placeholder={intl.messages.search}
          value={searchVal}
          onChange={onSearch}
          onFocus={onSearchFocus}
          onClick={onSearchFocus}
        />
        {isMobile && (
          <div
            className="search_close_wrapper show_mobile"
            onClick={() => setShowSearch(false)}
          >
            <Image src={CloseMobileIcon} className="search_close" />
          </div>
        )}
      </div>
      {searchFocused && (
        <div className="search_result">
          <Tabs slider rounded onChange={onSearchTabChange}>
            <Tab>
              <span>{intl.messages.games}</span>
            </Tab>
            <Tab>
              <span>{intl.messages.providers}</span>
            </Tab>
          </Tabs>
          {searchResult?.length ||
          lastGameSearches?.length ||
          lastProviderSearches?.length ? (
            <List>
              {getSearchListData().map((data) => (
                <ListItem
                  key={
                    searchTab === SEARCH_TAB_IDS.GAMES ? data.Key : data.Name
                  }
                >
                  <LinkWrapper
                    href={
                      searchTab === SEARCH_TAB_IDS.GAMES
                        ? `/games/${data.Key}`
                        : `/providers/?provider=${data.Name}`
                    }
                  >
                    <div
                      className="info"
                      onClick={() =>
                        searchTab === SEARCH_TAB_IDS.GAMES
                          ? onSearchGameSelect(data)
                          : onSearchProviderSelect(data)
                      }
                    >
                      {data.Image && (
                        <img
                          className="search_img"
                          src={data.Image}
                          alt={data.Name}
                        />
                      )}
                      {data.Name}
                    </div>
                  </LinkWrapper>
                  {!searchResult?.length ? (
                    <div
                      className="remove_wrapper"
                      onClick={() => onLastSearchItemRemove(data.Key)}
                    >
                      <Image
                        className="remove_img"
                        src={CloseIcon}
                        alt="remove"
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </ListItem>
              ))}
            </List>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );

  return (
    <>
      <AppBar className="main_header">
        {isMobile ? (
          <div className="show_mobile">
            <LinkWrapper href="/">
              <Image
                src={LogoVerticalImg}
                width={42}
                height={32}
                alt={`${websiteConfig.name} Logo`}
                className="logo_img"
              />
            </LinkWrapper>
          </div>
        ) : (
          <div className="show_desktop">
            <LinkWrapper href="/">
              <Image
                src={LogoHorizontalImg}
                width={114}
                height={38}
                alt={`${websiteConfig.name} Logo`}
                className="logo_img"
              />
            </LinkWrapper>
          </div>
        )}
        <div className="bar_actions">
          {!isMobile && (
            <div className="show_desktop promo_link_wrapper">
              <LinkWrapper href="/promotions">
                <PromoButton>{intl.messages.promotions}</PromoButton>
              </LinkWrapper>
            </div>
          )}
          {!isMobile && <div className="show_desktop">{Search}</div>}
          {!id ? (
            <>
              <LinkWrapper href="/login" className="action_wrapper">
                <Button variant="contained">{intl.messages.login}</Button>
              </LinkWrapper>
              <LinkWrapper href="/sign-up" className="action_wrapper">
                <Button variant="text" color="var(--primary)">
                  {intl.messages.register}
                </Button>
              </LinkWrapper>
            </>
          ) : (
            <>
              <div className="balance_wrapper action_wrapper">
                <NumericFormat
                  value={balance}
                  className="balance_amount"
                  displayType="text"
                  thousandSeparator
                  renderText={(value, renderProps) => (
                    <span {...renderProps}>{value || '0'}</span>
                  )}
                />
                <span className="currency">{websiteConfig.currency}</span>
              </div>
              <LinkWrapper
                href="/deposit"
                privateRoute
                className="action_wrapper"
              >
                <Button variant="contained">{intl.messages.deposit}</Button>
              </LinkWrapper>
            </>
          )}
          {isMobile && (
            <div className="search_icon_wrapper action_wrapper show_mobile">
              <Image
                src={SearchMobileIcon}
                onClick={() => {
                  setShowSearch(!showSearch);
                }}
                width={18}
                height={18}
                alt="Search"
              />
              {showSearch && (
                <div className="mobile_search_wrapper">{Search}</div>
              )}
            </div>
          )}
          {!isMobile && (
            <Select
              className="show_desktop action_wrapper"
              value={locale}
              onChange={onLanguageChange}
            >
              <option value="en">EN</option>
              <option value="sw">SW</option>
            </Select>
          )}
          <div className="menu" onClick={() => dispatch(setDrawerOpen(true))}>
            <span className="icon-menu menu_icon" />
          </div>
        </div>
      </AppBar>
      <style jsx>{styles}</style>
    </>
  );
}
