export async function onLanguageChange(e) {
  if (typeof e === 'number') {
    switch (e) {
      case 0:
        window.location.href = `/sw`;
        break;
      case 1:
        window.location.href = `/en`;
        break;
      default:
        break;
    }
  } else {
    window.location.href = `/${e.target.value}`;
  }
}
